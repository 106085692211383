import React from 'react';
import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Home1 from './components/Home1';
import AboutSection from './components/AboutSection';
import ContactSection from './components/ContactSection';



class Index1 extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
  }

  render() {

    return (
      <React.Fragment>

        {/* preloader */}
        <Preloader />

        {/* Navigation Menu */}
        <Navbar />

        {/* HomeSection Menu */}
        <Home1 />

        {/* AboutSection Menu */}
        <AboutSection />


        <ContactSection />



      </React.Fragment>

    );
  }
}

export default Index1;