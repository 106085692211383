import React from 'react';
import { Row,Col } from 'reactstrap';


class Home1 extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section home-2-bg" id="home">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <Row className="align-items-center">
                                    <Col lg="4">
                                        <div className="mt-40 home-2-content">
                                            <h1 className="text-white font-weight-normal home-2-title display-4 mb-0">Hilfsfrist-übersicht in Echtzeit.</h1>
                                            <p className="text-white-70 mt-4 f-15 mb-2">Jederzeit die perfekte Übersicht über die Abdeckungsrate Ihres Leitstellenbereichs.</p>
                                            <div className="mt-5">
                                                <a href="#about" className="btn btn-custom mr-4">Erfahren Sie mehr</a>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col lg="7">
                                        <div className="mt-40 home-2-content position-relative">
                                            <img src="images/home-2-img.png" alt="" className="img-fluid mx-auto d-block home-2-img mover-img" />
                                            <div className="home-2-bottom-img">
                                                <img src="images/homr-2-bg-bottom.png" alt="" className="img-fluid d-block mx-auto" />
                                            </div>
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Home1;