import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button, Modal,  ModalBody, ModalFooter, Spinner } from 'reactstrap';
import axios from 'axios';

class ContactSection extends React.Component {


    constructor(props) {
        super(props);
    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {popup:false,spinner:false};
      }


      componentDidMount () {
        axios.get('/rest/prealert').then(data => console.log('mailform provided'))
      }
    
      handleSubmit(event, errors, values) {
        this.setState({errors, values});
        console.log(event,errors,values)
        if (errors.length === 0) {
            this.setState({spinner:true})
            axios.post('/rest/mailform',{values}).then(res => {
                console.log(res);
                console.log(res.data);
                this.setState({popup:true,spinner:false})
            })
        }
      } 
      


    render() {

        return (
            <React.Fragment>
                <section className="section bg-light bg-contact" id="contact">
                    <div className="container bg-light-container">
                        <div className="row">
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Kontakt</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </div>

                        <Row>
                            <Col lg="12">
                                <div className="contact-box p-5">
                                    <Row>
                                        <Col lg="8" md="6">
                                            <div className="custom-form p-3">
                                                <div id="message"></div>
                                                <AvForm id="contact-form" onSubmit={this.handleSubmit}>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="form-group app-label">
                                                                <AvField name="name" type="text" validate={{ required: { value: true } }}  placeholder="Name" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="form-group app-label">
                                                                <AvField name="email" type="text" errorMessage="Invalid Email" validate={{ required: { value: true }, email: { value: true } }} placeholder="Email" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="subject" type="text" validate={{ required: { value: true } }} placeholder="Betreff" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="comments" type="textarea" validate={{ required: { value: true } }} rows="5"  placeholder="Ihre Nachricht" />
                                                            </div>
                                                        </Col>
                                                        <Row>
                                                            <Col sm="12">
                                                              &nbsp; &nbsp;  <input type="submit" className="submitBnt btn btn-custom" value="Absenden" />{this.state.spinner && <Spinner color="success" />}
                                                                <div id="simple-msg"></div>
                                                            </Col>
                                                        </Row>

                                                    </Row>
                                                </AvForm>
                                            </div>
                                        </Col>

                                        <div className="col-lg-4 col-md-6">
                                            <div className="contact-cantent p-3">
                                                <div className="contact-details">
                                                    <div className="float-left contact-icon mr-3 mt-2">
                                                        <i className="mdi mdi-headphones text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13">Telefon :</p>
                                                        <p className="mb-0 f-13"><a href="tel:024519103091">02451/9103091</a></p>                                                        
                                                    </div>
                                                </div>

                                                <div className="contact-details mt-2">
                                                    <div className="float-left contact-icon mr-3 mt-2">
                                                        <i className="mdi mdi-email-outline text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13">Email :</p>
                                                        <p className="mb-0 f-13"><a href="mailto:info@lstlage.de">info@lstlage.de</a></p>
                                                    </div>
                                                </div>
                                                <div className="contact-details mt-2">
                                                    <div className="float-left contact-icon mr-3 mt-2">
                                                        <i className="mdi mdi-scale-balance text-muted h5"></i>
                                                    </div>
                                                    <div className="app-contact-desc text-muted pt-1">
                                                        <p className="mb-0 info-title f-13 addressblock">
                                                        LStLage<br/>
                                                        Dohmen & Kunze BOSlab GbR<br/>
                                                        Mahrstr. 25, 41849 Wassenberg<br/>
                                                        USt-ID: DE 234997429<br/>
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-container container">
                        <div className="row">

                        </div>
                    </div>
                    
                    <div>
                    <Modal isOpen={this.state.popup} toggle={e => this.setState({popup:false})} >
                        <ModalBody>
                            Vielen Dank für Ihre Nachricht. Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.
                        </ModalBody>
                        <ModalFooter>
                        <Button color="secondary" onClick={e => this.setState({popup:false})}>Schließen</Button>
                        </ModalFooter>
                    </Modal>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ContactSection;