import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Intelligente Informationen",
                "content": "Straßennetzgenaue Isochronen zur Darstellung der aktuellen Hilfsfristabdeckung durch Ihre Einsatzmittel.",
            }, {
                "icon": "mdi mdi-eye",
                "title": "Schneller Überblick",
                "content": "Identifizierung von unterversorgten Gebieten auf einen Blick, sowohl bevölkerungs- als auch flächenbezogen.",
            }, {
                "icon": "mdi mdi-cogs",
                "title": "Hochgradig konfigurierbar",
                "content": "Durch vielfältig vorkonfigurierbare Ansichten perfekt nutzbar als Anzeige auf leitstellenweiten Lagemonitoren oder im Detail direkt am Arbeitsplatz des Disponenten.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Auf einen Blick</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.AboutList.map((about, index) => {
                                return <Col lg="4" key={index}>
                                            <div className="about-box about-light text-center p-3">
                                                <div className="about-icon mb-4">
                                                    <i className={about.icon}></i>
                                                </div>
                                                <h4 className="font-weight-light"><Link to="#" className="text-dark">{about.title}</Link></h4>
                                                <p className="text-muted f-14">{about.content}</p>
                                            </div>
                                        </Col>
                              })}
                         </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="about-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Beschreibung</h3>
                                    <p className="text-muted f-15">LStLage unterstützt Ihre Disponenten bei ihrer verantwortungsvollen Arbeit, indem einsatzbedingte Versorgungslücken frühzeitig auf einen Blick erkannt und Gegenmaßnahmen eingeleitet werden können. Hierfür stellt der Service nahezu in Echtzeit die von den Einsatzmitteln innerhalb der Hilfsfrist erreichbaren Gebiete inklusive qualitativer und quantitativer Kenngrößen übersichtlich dar und vereinfacht die Entscheidung über sinnvolle prophylaktische Fahrzeugverschiebungen deutlich – auch ohne detaillierte Kenntnisse des Straßennetzes.
Ihr Leitstellenpersonal wird in den stressigen Phasen mehrerer gleichzeitig gebundener Rettungsmittel entlastet. Es werden Kapazitäten geschaffen, wo sie benötigt werden: bei der Betreuung und Koordinierung individueller Einsatzgeschehen.
                                        </p>
                                </div>
                            </Col>

                            <Col md="6">
                                <div className="about-img light-img position-relative p-4">
                                    <img src="images/full-size.jpg" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;