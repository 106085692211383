import React from 'react';
import ScrollspyNav from './scrollSpy';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { Tab: '' };
    }

    /**
     * Sets active tab
     */
    setActiveTab = (tab, e) => {
        this.setState({ Tab: tab });
    }

    render() {

        return (
            <React.Fragment>
                <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                    <div className="container">
                    {/* <div className="mt-40 home-2-content"> */}
                                            <h1 className="text-white font-weight-bold home-2-title display-4 mb-0">LStLage</h1>
                    {/* </div> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>


                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ScrollspyNav
                                scrollTargetIds={["home", "about", "contact"]}
                                activeNavClass="active"
                                scrollDuration="800"
                                headerBackground="false"
                                className="ml-auto">
                                <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                                    <li className="nav-item active"><a href="#home" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse">Start</a></li>
                                    <li className="nav-item"><a href="#about" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse">Über</a></li>
                                    {/* <li className="nav-item"><a href="#services" className="nav-link">Services</a></li>*/}
                                     {/* <li className="nav-item"><a href="#features" className="nav-link">Eigenschaften</a></li>  */}
                                    {/*<li className="nav-item"><a href="#pricing" className="nav-link">Pricing</a> </li>
                                    <li className="nav-item"><a href="#clients" className="nav-link">Clients</a></li>
                                    <li className="nav-item"><a href="#blog" className="nav-link">Blog</a></li> */}
                                    <li className="nav-item"><a href="#contact" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse">Kontakt</a> </li>
                                </ul>
                            </ScrollspyNav>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }

}

export default Navbar;